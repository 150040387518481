import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
	Navigate
} from "react-router-dom";
import './index.css';
import Map from './map/map';
import Home from "./home/home";
import reportWebVitals from './reportWebVitals';
import Thanks from "./thanks/thanks";
import Page from "./page/page";
import AddDescription from "./add-description/add-description";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/map",
    element: <Map />
  },
  {
    path: "/info",
    element: <Navigate to="/page/information-til-besogende" />
  },
  {
    path: "/vendor-info",
    element: <Navigate to="/page/information-til-stadeholdere" />
  },
  {
    path: "/register",
    element: <Navigate to="/page/tilmelding" />
  },
	{
		path: "/page/:slug",
		element: <Page />
	},
	{
		path: "/add-description",
		element: <AddDescription />
	}
]);

// eslint-disable-next-line
const thanksRouter = createBrowserRouter([
  {
    path: "/",
    element: <Thanks />
  }
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
