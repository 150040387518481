import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Thanks({ children }) {

    return (
        <div className="bg-hero-pattern h-screen w-screen bg-cover bg-center overflow-hidden">
            
            <Helmet>
            <title>Lopper i Skæring</title>
        </Helmet>
            <div className="h-full w-100 space-y-4 flex flex-col justify-center">
                <div className="mb-6 text-center font-titan uppercase">
                    <h1 className="mb-4 leading-tight text-7xl text-white drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)]">
                        Lopper
                        <span className="block text-5xl"> i Skæring</span>
                    </h1>
                    <span className="text-[#cbb4d8] text-3xl drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]">
                        Tak for denne gang<br />
                        Vi ses igen!
                    </span>
                </div>
                <div className="text-center">
                    <span className="inline-block text-base text-white drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]" to="info">
                        Har du forslag eller feedback til næste udgave?
                    </span>
                </div>
                <div className="text-center">
                    <Link className="inline-block py-4 px-4 text-xl uppercase font-titan bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md" to="mailto:feedback@strandlopper.dk">
                        Giv feedback
                    </Link>
                </div>
            </div>

        </div>
    );
}

