import { Link } from "react-router-dom";
import Banner from "./banner";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import sanityClient from "./../sanity/client";

import poster from "./../static/lopper_a4.pdf";

export default function Home({ children }) {
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`count(*[_type == "vendor"])`)
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  console.log(posts);

  return (
    <div className="bg-hero-pattern h-screen w-screen bg-cover bg-center overflow-hidden">
      <Helmet>
        <title>Lopper i Skæring</title>
      </Helmet>
			<Banner />
      <div className="h-full w-100 space-y-2 flex flex-col justify-center">
        {" "}
        <div className="mb-10 flex flex-col space-y-6 text-center font-titan uppercase">
          <h1 className="leading-tight text-7xl text-white drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)]">
            Lopper
            <span className="block text-5xl"> i Skæring</span>
          </h1>
          <span className="text-[#cbb4d8] text-4xl drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]">
            TAK FOR I ÅR!<br />
          </span>
					<span className="block text-white text-2xl drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]">
              Vi ses i mindelunden til august – mere info følger
            </span>
        </div>
        <div className="mb-10 text-center font-titan uppercase hidden">
          {posts && (
            <span className="block text-[#cbb4d8] text-2xl drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]">
              {posts} loppeboder i indkørsler og haver i hele Skæring!
            </span>
          )}
          <h1 className="mb-4 leading-tight text-7xl text-white drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)]">
            Lopper
            <span className="block text-5xl"> i Skæring</span>
          </h1>
          <span className="text-[#cbb4d8] text-3xl drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]">
            SØNDAG D. 5. MAJ <br />
            KL. 10–16 (cirka)
          </span>
        </div>
        <div className="text-center hidden">
          <Link
            className="inline-block py-4 px-4 text-xl uppercase font-titan bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md"
            to="/page/tilmelding">
            Tilmeld dig som stadeholder
          </Link>
        </div>
        <div className="text-center hidden">
          <Link
            className="inline-block py-4 px-4 text-xl uppercase font-titan bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md"
            to="map">
            Se kortet
          </Link>
        </div>
        <div className="space-y-1">
          <div className="text-center hidden">
            <Link
              className="inline-block py-1 px-1 text-base text-white drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]"
              to="/page/information-til-besogende">
              Information til besøgende
            </Link>
          </div>
          <div className="text-center hidden">
            <Link
              className="inline-block py-1 px-1 text-base text-white drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]"
              to="/page/information-til-stadeholdere">
              Information til stadeholdere
            </Link>
          </div>
          <div className="text-center hidden">
            <a
              target="_blank"
              rel="noreferrer"
              className="inline-block py-1 px-1 text-base text-white drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]"
              href={poster}>
              Hent plakat til print
            </a>
          </div>
          <div className="text-center hidden">
            <Link
              className="inline-block py-1 px-1 text-base text-white drop-shadow-[3px_3px_0px_rgba(0,0,0,0.3)]"
              to="map">
              Se kortet
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
