import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// eslint-disable-next-line
import Form from "./form";

export default function AddDescription({ children }) {
  return (
    <div className="bg-hero-pattern min-h-screen min-w-screen bg-cover bg-center">
      <Helmet>
        <title>{`Tilføj beskrivelse - Lopper i Skæring`}</title>
      </Helmet>
      <div className="h-full w-100  py-8 px-4">
        <div className="space-y-4 flex flex-col justify-center max-w-2xl mx-auto">
          <div className="flex items-center p-4 px-7 bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md text-lg lg:text-2xl">
            <Link to="/" className="pr-3">
              ←
            </Link>
            <h1 className="font-bold">Tilføj beskrivelse</h1>
          </div>
          <div className="z-50 p-7 text-md lg:text-lg bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md [&_p:not(:last-child)]:mb-4">
            <Form />
          </div>
          <div className="z-0 flex p-3 px-7 bg-white text-[#0f2e10] drop-shadow-[6px_6px_0px_rgba(0,0,0,0.3)] rounded-md text-lg lg:text-2xl">
            <Link to="/" className="text-lg">
              Tilbage til forsiden
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
