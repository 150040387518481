// Autocomplete.js
import React, { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import sanityClient from "./../sanity/client";

function Autocomplete({ onChange }) {
  const [query, setQuery] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null); // State to hold the selected address object
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    if (query.length > 2) {
      sanityClient
        .fetch(`*[_type == "vendor" && address match "${query}*"]`) // Use match operator to filter results
        .then((data) => setAddresses(data))
        .catch(console.error);
    } else {
      setAddresses([]);
    }
  }, [query]);

  const handleSelect = (address) => {
    setSelectedAddress(address); // Set the selected address state
    onChange(address); // Update parent component state
  };

  return (
    <Combobox as="div" value={selectedAddress} onChange={handleSelect}>
      <div className="relative mt-2">
        <Combobox.Input
          autoComplete="off"
          placeholder="Indtast din adresse"
					className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

          onChange={(event) => setQuery(event.target.value)}
          displayValue={(address) => (address ? address.address : "")} // Show the address text in the input
        />
        {addresses.length > 0 && (
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        )}
        {addresses.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {addresses.map((address, index) => (
              <Combobox.Option
                key={index}
                value={address}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-9 ${
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  }`
                }>
                {({ active, selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected && "font-semibold"
                      }`}>
                      {address.address}
                    </span>
                    {selected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                          active ? "text-white" : "text-indigo-600"
                        }`}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export default Autocomplete;
