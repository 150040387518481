// import { SunIcon } from "@heroicons/react/20/solid";
import { SunIcon } from "@heroicons/react/24/outline";
import './banner.css';
const greetings = [
    "Vær vejenes ven – sænk farten",
    "Husk: smukke mennesker kører langsomt",
    "Vi ❤️ gæster der sænker farten",
    "Kør langsomt, så er du en stjerne",
    "Loppejagt med omtanke – Sænk farten",
    "Sænk farten, så sænker vi priserne",
    "Tag den med ro på vejene – der er loppefund nok til alle",
]

function shuffleArray(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

export default function Banner() {
    const shuffled = shuffleArray(greetings);
    return (
        <>
            <SunIcon className="z-10 absolute top-2 left-4 mx-auto animate-spin-slow h-6 w-6 text-[#cbb4d8]" />
            <SunIcon className="z-10 absolute top-2 right-4 mx-auto animate-spin-slow h-6 w-6 text-[#cbb4d8]" />
            <SunIcon className="z-10 absolute bottom-4 left-4 mx-auto animate-spin-slow h-6 w-6 text-[#cbb4d8]" />
            <SunIcon className="z-10 absolute bottom-4 right-4 mx-auto animate-spin-slow h-6 w-6 text-[#cbb4d8]" />
            <span className="absolute top-0 left-0 bg-white p-2 text-[#0f2e10] font-sans w-full overflow-hidden">
                <span className="z-[5] absolute top-0 left-0 h-full w-20 bg-gradient-to-l from-transparent via-white/95 to-white" />
                <span className="z-[5] absolute top-0 right-0 h-full w-20 bg-gradient-to-r from-transparent via-white/95 to-white" />

                <div className="Marquee inline-block whitespace-nowrap">
                    {[...shuffled, ...shuffled].map((message, i) => (
                        <div key={i} className="inline-block">
                            <span className="text-xs font-semibold normal-case">{message}</span>
                            <span className="text-xs font-semibold normal-case px-4">·</span>
                        </div>
                    ))}
                </div>
            </span>
        </>
    )
}
