import { ArrowTrendingUpIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "./map.css";

import sanityClient from "./../sanity/client";

import { Helmet } from "react-helmet";

const escapeHTML = (str) =>
  str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

const MapboxGeocoder = require("@mapbox/mapbox-sdk/services/geocoding");
const geocoder = MapboxGeocoder({
  accessToken:
    "pk.eyJ1Ijoiam9uYXNldGhvbXNlbiIsImEiOiJjbGh0ZXo4djUwZ2o0M2xvMWZuam8zOTR5In0.BYmhTemFBSjsvIc14tsyCA",
});

mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9uYXNldGhvbXNlbiIsImEiOiJjbGh0ZXo4djUwZ2o0M2xvMWZuam8zOTR5In0.BYmhTemFBSjsvIc14tsyCA";

export default function App() {
  const [posts, setPosts] = useState([]);
  const [located, setLocated] = useState(false);
  const mapContainer = useRef(null);
  const map = useRef(null);
  // eslint-disable-next-line
  const [lng, setLng] = useState(10.312791);
  // eslint-disable-next-line
  const [lat, setLat] = useState(56.233605);
  // eslint-disable-next-line
  const [zoom, setZoom] = useState(12);
  const [markers, setMarkers] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "vendor"]`)
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
      showUserLocation: true,
    });

    map.current.addControl(geolocateControl);

    geolocateControl.on("geolocate", (event) => setLocated(true));
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (markers) return; // wait for map to initialize
    const totalNum = posts.length;
    let current = 0;
    posts.forEach((address) => {
      if (!address.zip || !address.city || !address.address) {
        return;
      }
      geocoder
        .forwardGeocode({
          query: `${address.address}, ${address.zip} ${address.city}`,
          autocomplete: false,
          limit: 1,
        })
        .send()
        .then((response) => {
          if (
            !response ||
            !response.body ||
            !response.body.features ||
            !response.body.features.length
          ) {
            console.error("Invalid response:");
            console.error(response);
            return;
          }
          const feature = response.body.features[0];

          // Create a marker and add it to the map.
          current++;
          new mapboxgl.Marker({
            color: "black",
            scale: 0.7,
          })
            .setLngLat(feature.center)
            .setPopup(
              new mapboxgl.Popup().setHTML(
                `<span class='box'>
									<span class='block font-bold'>${address.address}</span>
									${
                    address.description
                      ? `<span>${escapeHTML(address.description).replace(
                          /\n/g,
                          "<br>"
                        )}</span>`
                      : ""
                  }
									</span>`
              )
            )
            .addTo(map.current);
          if (current === totalNum) {
            setMarkers(true);
          }
        });
    });
  }, [posts, markers]);

  return (
    <div>
      <Helmet>
        <title>Kort - Lopper i Skæring</title>
      </Helmet>
      <div className="absolute top-3 left-2 z-[100]">
        <Link to="/">
          {" "}
          <HomeIcon className="p-1.5 bg-white  h-7 w-7 text-gray-800 shadow-md rounded-md ring-1 ring-gray-300" />
        </Link>
      </div>

      {!located && (
        <div className="absolute top-6 -right-2 z-[100] text-white pointer-events-none">
          <ArrowTrendingUpIcon className="p-1.5  h-10 w-10 drop-shadow-[1px_1px_0px_rgba(0,0,0,0.8)] text-white shadow-md rounded-md" />
          <span className="relative right-6 -top-3 drop-shadow-[1px_1px_0px_rgba(0,0,0,0.8)]">
					Klik her<br/>
					for at vise<br/>
					din placering
					</span>
        </div>
      )}

      <div className="absolute top-2 left-12 right-12 z-[100]">
        <div className="text-center bg-white shadow p-1 text-sm text-[#0f2e10] rounded-md hidden">
          <p>
            Kortet er <strong>under opbygning.</strong>
            <br />
            Læs mere under{" "}
            <Link className="underline" to="/vendor-info">
              Information til stadeholdere
            </Link>
          </p>
        </div>
      </div>
      <div ref={mapContainer} className="map-container" />
    </div>
  );
}
