import React, { useState } from "react";
import client from "./../sanity/write-client";
import Autocomplete from "./autocomplete";

const Form = () => {
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [telephone, setTelephone] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const validateDescription = (text) => {
    if (text.length > 200) {
      return "Maksimal længde for dette felt er 200 tegn.";
    }
    if (text.toUpperCase() === text && /[A-Z]/.test(text)) {
      return "Tekst udelukkende i store bogstaver er ikke tilladt.";
    }
    if (/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(text)) {
      return "Emojis er ikke tilladte.";
    }
    if (text.replace(/[a-zA-Z0-9\s,.&]/g, "").length > 10) {
			return "Overdreven brug af symboler er ikke tilladt.";
		}
    if ((text.match(/!/g) || []).length > 3) {
      return "Overdreven brug af udråbstegn er ikke tilladt.";
    }
    let uppercaseMatch = text.match(/[A-Z]/g);
    if (uppercaseMatch && uppercaseMatch.length > 20) {
      return "Overdreven brug af store bogstaver er ikke tilladt.";
    }
    return null;
  };

  const validateDanishPhoneNumber = (phoneNumber) => {
    // Strip all non-numeric characters for simplicity
    const cleanNumber = phoneNumber.replace(/\D/g, "");

    // Check if the number has exactly 8 digits (Danish standard)
    if (!/^\d{8}$/.test(cleanNumber)) {
      return "Indtast venligst et gyldigt telefonnummer.";
    }

    // Optional: Check if the number starts with a valid Danish area or mobile code if necessary
    return null; // Return null if no error is found
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError(null);

    if (!address) {
      setError("Indtaste venligst din adresse");
      setIsSubmitting(false);
      return;
    }

    if (!description) {
      setError("Beskrivelse er påkrævet");
      setIsSubmitting(false);
      return;
    }

    let validationError = validateDanishPhoneNumber(telephone);
    if (validationError) {
      setError(validationError);
      setIsSubmitting(false);
      return;
    }

    validationError = validateDescription(description);
    if (validationError) {
      setError(validationError);
      setIsSubmitting(false);
      return;
    }

    try {
      let result = await client
        .patch(address._id)
        .set({
          description: description,
        })
        .commit();
      console.log("Description added", result);
      setError(null);
      setSuccess("Beskrivelse tilføjet");
    } catch (error) {
      console.error("Oh no, the update failed: ", error.message);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={isSubmitting ? "space-y-4 animate-pulse" : "space-y-4"}>

<div class="space-y-2">
            <p>
              Skriv en kort beskrivelse af hvad i sælger. Beskrivelsen bliver
              synlig på kortet. Se retninglinjer for beskrivelsen nederst på siden.
            </p>
          </div>
      {error && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4"
          role="alert">
          <h3 className="text-sm font-medium text-red-800">{error}</h3>
        </div>
      )}
      {success && (
        <>
          <div
            className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4"
            role="alert">
            <h3 className="text-xl font-medium">{success}</h3>
          </div>
        </>
      )}
      {!success && (
        <div className="space-y-4">
          <Autocomplete onChange={setAddress} />
          <div>
            <textarea
              type="text"
              name="description"
              id="description"
              placeholder="Beskrivelse"
              onChange={(event) => setDescription(event.target.value)}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={""}
              rows={4}
            />
            <p className="text-xs text-right text-slate-400 block mt-1.5">
              {description.length}/200
            </p>
          </div>
          <div>
            <input
              type="text"
              name="telephone"
              id="telephone"
              placeholder="Indtast telefonnummer"
              onChange={(event) => setTelephone(event.target.value)}
              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <p className="text-xs text text-slate-400 block mt-1.5">
              Indtast telefonnummeret der blev brugt ved tilmelding
            </p>
          </div>
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {isSubmitting ? "Processing..." : "Tilføj beskrivelse"}
            </button>
          </div>

          <div className="text-sm mt-4">
            <p>
              - Maksimal længde er 200 tegn.
              <br />
              - Tekst udelukkende i store bogstaver er ikke tilladt.
              <br />
              - Emojis er ikke tilladte.
              <br />
              - Overdreven brug af symboler er ikke tilladt.
              <br />
              - Overdreven brug af udråbstegn er ikke tilladt.
              <br />
							- Overdreven brug af store bogstaver er ikke tilladt.
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default Form;
